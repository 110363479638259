import { IAPIModel } from "@Types/services/api/utils/commonTypes";

let instance: API | undefined;

class API {
  constructor(private APIControl: IAPIModel) {
    if (instance) {
      throw new Error("New instance cannot be created!!");
    }

    instance = this;
  }

  static getInstance() {
    return instance!.APIControl!;
  }

  static destroyInstance() {
    instance = undefined;
  }
}

export { API };
