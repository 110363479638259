import { create } from "zustand";

import * as Types from "./types";

const FORGET_PASSWORD_INITIAL_STATE: Types.ForgetPasswordProps = {
  token: null,
  type: null,
  flow: null,
  isPasswordConfigurationFlow: false,
};

const ForgetPasswordStore = create<Types.ForgetPasswordStore>((set, get) => ({
  forgetPasswordData: FORGET_PASSWORD_INITIAL_STATE,

  clearForgetPasswordData: () =>
    set({ forgetPasswordData: FORGET_PASSWORD_INITIAL_STATE }),

  setForgetPasswordData: (props: Partial<Types.ForgetPasswordProps>) => {
    const { forgetPasswordData: prevState } = get();

    set({
      forgetPasswordData: {
        ...prevState,
        ...props,
      },
    });
  },
}));

export { ForgetPasswordStore };
