import axios, { AxiosError, AxiosInstance } from "axios";
import * as Types from "@Types/services/api/utils/connection";
import { RESPONSE_ADAPTERS } from "../adapters";
import { API_URLS } from "../../../constants/application";
import { setApiHeaders, verifyAuthorizationOnRequest } from "./helpers";

const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 2000;
const AVEC_SALONS_LIST_IDS_IN_PRODUCTION = [66, 97902, 98479, 80525];

const delay = (milliseconds: number): Promise<void> => {
  if (typeof milliseconds !== "number" || milliseconds <= 0) {
    throw new Error(
      "Invalid delay time -> createAPIConnection retry on Network Error"
    );
  }

  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const createAPIConnection = ({
  app,
  salonId,
  jwtClient,
}: Types.CreateAPIConnectionProps): AxiosInstance => {
  const baseURL = API_URLS[app];
  const adapters = RESPONSE_ADAPTERS[app];

  const api = axios.create({ baseURL });

  setApiHeaders({
    api,
    headerProps: [{ key: "JWT_CLIENT", value: jwtClient }],
  });

  const validSalonId = salonId ? Number(salonId) : null;

  // Implementação do teste de 'retry' da conexão quando usuário receberem algum erro de NetworkError
  // Medida paleativa que pode ser evoluida caso necessário
  const salonIncludedToTestNetworkError =
    AVEC_SALONS_LIST_IDS_IN_PRODUCTION.includes(validSalonId);

  if (validSalonId && salonIncludedToTestNetworkError) {
    api.interceptors.response.use(
      (response) =>
        adapters.onResolve ? adapters.onResolve(response) : response,
      async (error: AxiosError) => {
        const config: any = error.config;
        config.__retryCount = config.__retryCount || 0;

        if (
          config.__retryCount < MAX_RETRIES &&
          (!error.response || error.message.includes("Network Error"))
        ) {
          config.__retryCount += 1;

          await delay(RETRY_DELAY_MS);

          return api(config);
        }

        return adapters.onReject
          ? adapters.onReject(error)
          : Promise.reject(error);
      }
    );
  } else if (adapters.onReject && adapters.onResolve) {
    api.interceptors.response.use(
      (response) => adapters.onResolve(response),
      (error) => adapters.onReject(error)
    );
  }

  verifyAuthorizationOnRequest(api, app);

  return api;
};

export { createAPIConnection };
