import CreateCustomEvent from "../createCustomEvent";

type ExitLoginFlowEventDetailProps = {
  data: any;
  success: boolean;
  isBanking: boolean;
  isMFAActive: boolean;
  cognitoToken?: string;
  application_token: string;
  guid_account: string;
};

export const ExitLoginFlowEvent =
  new CreateCustomEvent<ExitLoginFlowEventDetailProps>("exitLoginFlow");
