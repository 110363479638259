import { getUserTypeByJWT } from "@Utils/jwtUtils/";
import { AuthLocationStore } from "@Store/authLocations";

// Application map to Avec redirect URLs
const AVEC_B2B_APPLICATION_URLS = {
  avecGo: "https://avecgo.com/",
  plataformaSalaoVip: "https://admin.avec.beauty/",
  // TODO: Quais as rotas pra esses produtos
  // TODO: Falta algum produto
  avecPro: "https://precisa-informar-a-rota-login",
  terminalSVIP: "https://precisa-informar-a-rota-login",
};

// Function to map JWT client to the correct application
const mapJwtClientToApplication = (jwtClient: string) => {
  const jwtToApplicationMap = {
    "@VeCg0Pw@": "avecGo",
    "S@laoV1P": "plataformaSalaoVip",
    T3rm1n4lM0b1l3: "avecPro",
    "T3rmin@lSV1P": "terminalSVIP",
  };

  return jwtToApplicationMap[jwtClient];
};

// Function to get the professional redirect URL based on the JWT client
const getProfessionalRedirectUrl = (jwtClient: string) => {
  const application = mapJwtClientToApplication(jwtClient);
  const authLocation = AuthLocationStore.getState().selectedAuthLocation;
  const slug = authLocation && authLocation?.slug;

  if (application === "plataformaSalaoVip") {
    return `${AVEC_B2B_APPLICATION_URLS[application]}${slug}/admin`;
  }

  return AVEC_B2B_APPLICATION_URLS[application];
};

// Function to get redirect URL based on the user type
const getRedirectUrlByUserType = (jwtClient: string) => {
  const userType = getUserTypeByJWT(jwtClient);

  const redirectUrlByUserType = {
    cliente: "https://avec.app/",
    qg: "https://admin2.pvt.avec.beauty/qg",
    profissional: getProfessionalRedirectUrl(jwtClient),
  };

  return redirectUrlByUserType[userType];
};

// Main function to handle redirect URL generation for Avec vertical
const handleAvecRedirectUrl = (jwtClient: string) => {
  return getRedirectUrlByUserType(jwtClient);
};

// Function to generate the redirect URL based on the vertical and JWT client
export const generateRedirectUrl = (
  jwtClient: string,
  vertical: "Avec" | "CrossX"
) => {
  const redirectUrlByVertical = {
    Avec: handleAvecRedirectUrl(jwtClient),
    CrossX: "https://dash.appcrossx.com/auth/login",
  };

  return redirectUrlByVertical[vertical];
};
