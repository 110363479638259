import StorageManager from "@Services/localStorageManager";

export const recoveryStoredAppAuthToken = (appName: AppNames) => {
  const storedAuth = StorageManager.getItem("auth");

  if (!storedAuth) return;

  const parsedStoredAuth = JSON.parse(storedAuth);
  const appAuthToken = parsedStoredAuth[appName]?.authToken;

  return appAuthToken;
};

export const parseBankingAndMFAStatus = (authSession) => {
  const { is_banking, is_mfa_active } =
    authSession?.tokens?.accessToken.payload;

  const isBanking: boolean = is_banking === "true";
  const isMFAActive: boolean = is_mfa_active === "true";

  return { isBanking, isMFAActive };
};
