import { create } from "zustand";

import * as Types from "./types";

const SigninAppStore = create<Types.SigninAppStoreProps>((set) => ({
  appName: null,
  vertical: null,
  userType: null,
  jwtClient: null,
  userFromCustomLink: null,
  setUserType: (userType: UserTypes) => set({ userType }),
  setSigninAppProps: (props: SigninAppProps) => set({ ...props }),
}));

export { SigninAppStore };
