import { I18n } from "aws-amplify/utils";
import { DebuggingTrack } from "@Libs/debugging";
import * as Types from "./types";
import axios from "axios";

const KNOWN_COGNITO_ERRORS = new Set([
  "NotAuthorizedException",
  "UserNotFoundException",
  "CodeMismatchException",
  "LimitExceededException",
  "EnableSoftwareTokenMFAException",
  "UserAlreadyAuthenticatedException",
]);

const handleAxiosError = (error, errorMessage) => {
  const { response, config } = error;

  if (response?.status >= 400 && response?.status <= 499) {
    throw new Error(errorMessage);
  }

  const errorData = {
    // Informações da solicitação
    url: config?.url,
    method: config?.method,
    data: config?.data,
    headers: config?.headers,

    // Informações da resposta
    status: response?.status,
    statusText: response?.statusText,
    responseHeaders: response?.headers,
    responseData: response?.data,

    message: error.message,
  };

  // Sanitiza dados sensíveis
  if (errorData.headers && errorData.headers.Authorization) {
    errorData.headers.Authorization = null;
  }

  if (errorData.data && typeof errorData.data === "string") {
    try {
      const parsedData = JSON.parse(errorData.data);

      // Remove campos sensíveis
      const sensitiveFields = [
        "email",
        "password",
        "token",
        "token_push",
        "cognito_token",
      ];
      sensitiveFields.forEach((field) => {
        if (parsedData[field]) {
          parsedData[field] = null;
        }
      });

      errorData.data = JSON.stringify(parsedData);
    } catch (e) {
      // Deixa o corpo dos dados original caso não seja JSON
    }
  }

  return errorData;
};

const handleException = ({
  exception,
  errorCrumbs,
}: Types.HandleExceptionProps) => {
  const _exception = exception?.rawError || exception;
  const errorMessage = exception?.message || "Ocorreu um erro inesperado";

  // Verifica se o erro é um erro Cognito conhecido
  if (
    errorCrumbs.category.toLowerCase().includes("cognito") &&
    KNOWN_COGNITO_ERRORS.has(_exception?.name)
  ) {
    throw new Error(I18n.get(errorMessage));
  }

  // Verifica se o erro é um erro de requisição comum (axios) e extrai informações
  let errorData = {};
  if (axios.isAxiosError(_exception)) {
    errorData = handleAxiosError(_exception, errorMessage);
  }

  const data = { _exception, ...errorCrumbs, errorData };
  const timestampToSeconds = Date.now() / 1000;

  DebuggingTrack.addBreadcrumb({
    data,
    type: "Auth",
    level: "error",
    message: errorMessage,
    timestamp: timestampToSeconds,
    category: errorCrumbs.category,
  });

  DebuggingTrack.captureException(_exception);

  throw new Error(I18n.get(errorMessage));
};

export const performWithErrorHandling = async ({
  perform,
  onError,
  errorCrumbs,
}: Types.PerformWithErrorHandlingProps) => {
  try {
    return await perform();
  } catch (error: any) {
    try {
      onError?.(error);
    } catch (errorException: any) {
      handleException({ exception: error, errorCrumbs });
    }

    // Se `onError` não estiver definido ou lançar uma exceção, lidamos com a exceção aqui
    handleException({ exception: error, errorCrumbs });
  }
};
