import { CurrentUserStore } from "@Store/currentUser";
import { UserCredentialsStore } from "@Store/userCredentials";

import { ExitLoginFlowEvent } from "@Helpers/customEvents/exitLoginFlowEvent";

function exitLoginFlowEvent({
  data,
  cognitoToken,
  applicationToken,
  guid_account,
  isCompletingFlow = true,
}) {
  const { isMFAActive, isBanking } = CurrentUserStore.getState().currentUser;
  const { clearUserCredentials } = UserCredentialsStore.getState();

  clearUserCredentials();

  return ExitLoginFlowEvent.dispatchEvent({
    data,
    isBanking,
    isMFAActive,
    cognitoToken,
    success: isCompletingFlow,
    application_token: applicationToken,
    guid_account,
  });
}

function onCompleteUserAuthentication(callback, once = false) {
  return ExitLoginFlowEvent.setEvent(async ({ detail }) => {
    return callback(detail);
  }, once);
}

export { exitLoginFlowEvent, onCompleteUserAuthentication };
