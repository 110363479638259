import { CurrentUserStore } from "@Store/currentUser";
import { SigninAppStore } from "@Store/signinAppProps";
import { AuthLocationStore } from "@Store/authLocations";

import { recoveryStoredAppAuthToken } from "@Helpers/auth";

import { PropsValidationMFEType } from "./types";

function getPropsValidationMFE(): PropsValidationMFEType {
  const { appName, jwtClient } = SigninAppStore.getState();

  const currentUser = CurrentUserStore.getState().currentUser;

  const authLocation = AuthLocationStore.getState().selectedAuthLocation;

  const authorizationToken = recoveryStoredAppAuthToken(appName);

  const user = {
    name: currentUser.name,
    id: currentUser.salonAccessId || currentUser.id,
    email: currentUser.email,
  };

  const company = {
    id: authLocation?.id || currentUser.companyId,
    name: authLocation?.name,
  };

  const accountValidationMFEProps = {
    user,
    company,
    jwtClient,
    theme: appName,
    token: authorizationToken,
    anotherRoomSalon: {
      id: currentUser?.anotherRoomSalon?.salao_id || [],
      name: currentUser.anotherRoomSalon?.salao_nome || [],
    },
  };

  return accountValidationMFEProps;
}

export { getPropsValidationMFE };
