import { AxiosError } from "axios";

export const handleAPIErrors = (error: AxiosError) => {
  const response: any = error?.response || {};
  const data: any = response?.data || {};

  const errorMessage = data?.message || error?.message;
  const isNetworkError = errorMessage.toLowerCase() === "network error";

  const userMessage = isNetworkError
    ? "Tente novamente em instantes"
    : errorMessage;

  return Promise.reject({
    rawError: error,
    code: data.code,
    message: userMessage,
  });
};

export const handleAPISuccess = (response) => Promise.resolve(response);
