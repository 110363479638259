import { getUserTypeByJWT } from "@Utils/jwtUtils/";
import { AMPLIFY_USER_POOL_CLIENT_ID } from "@Constants/amplify";

import { DebuggingTrack } from "@Libs/debugging";

import { setupAmplify } from "@Services/amplify";
import { APIFactory } from "@Services/api/factory";

import { SigninAppStore } from "@Store/signinAppProps/";
import { AuthLocationStore } from "@Store/authLocations";

function setupAuthUtility({
  appName,
  vertical,
  jwtClient,
  userPoolClientId,
  userFromCustomLink,
  deviceInfo,
}) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      DebuggingTrack.init();

      const userType = getUserTypeByJWT(jwtClient, vertical);

      const userPoolClientIdDefault =
        AMPLIFY_USER_POOL_CLIENT_ID[appName][
          userType === "cliente" ? "b2c" : "b2b"
        ];

      const salonId =
        userFromCustomLink?.authLocation?.id ||
        userFromCustomLink?.authLocation?.salonId;

      APIFactory(appName, jwtClient, salonId);

      setupAmplify({
        userType,
        userPoolClientId: userPoolClientId || userPoolClientIdDefault,
      });

      SigninAppStore.getState().setSigninAppProps({
        appName,
        vertical,
        jwtClient,
        userFromCustomLink,
        deviceInfo,
      });

      SigninAppStore.getState().setUserType(userType);

      if (userFromCustomLink && userFromCustomLink?.authLocation) {
        AuthLocationStore.getState().setSelectedAuthLocation(
          userFromCustomLink.authLocation
        );
      }

      return resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export { setupAuthUtility };
