import { create } from "zustand";

import * as Types from "./types";

const USER_CREDENTIALS_STATE: Types.UserCredentialsProps = {
  email: null,
  password: null,
};

const UserCredentialsStore = create<Types.UserCredentialsStore>((set, get) => ({
  userCredentials: USER_CREDENTIALS_STATE,

  clearUserCredentials: () => set({ userCredentials: USER_CREDENTIALS_STATE }),

  setUserCredentials: (props: Partial<Types.UserCredentialsProps>) => {
    const { userCredentials: prevState } = get();

    set({
      userCredentials: {
        ...prevState,
        ...props,
      },
    });
  },
}));

export { UserCredentialsStore };
