export const AMPLIFY_USER_POOL_ID = {
  b2b: process.env["B2B_AMPLIFY_USER_POOL_ID"],
  b2c: process.env["B2C_AMPLIFY_USER_POOL_ID"],
};

export const AMPLIFY_USER_POOL_CLIENT_ID = {
  avec: {
    b2b: process.env["AVEC_B2B_AMPLIFY_USER_POOL_CLIENT_ID"],
    b2c: process.env["AVEC_B2C_AMPLIFY_USER_POOL_CLIENT_ID"],
  },
  crossX: {
    b2b: process.env["CROSSX_B2B_AMPLIFY_USER_POOL_CLIENT_ID"],
    b2c: process.env["CROSSX_B2C_AMPLIFY_USER_POOL_CLIENT_ID"],
  },
};
