import { Amplify } from "aws-amplify";
import {
  signIn,
  signOut,
  setUpTOTP,
  confirmSignIn,
  verifyTOTPSetup,
  updateMFAPreference,
  fetchAuthSession,
  resetPassword,
  confirmResetPassword,
  ConfirmResetPasswordInput,
} from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";

import { performWithErrorHandling } from "@Utils/errorHandlerUtils";

import { CurrentUserStore } from "@Store/currentUser/";
import { AuthLocationStore } from "@Store/authLocations/";

import {
  getCognitoContextPool,
  getAmplifySignInOptions,
} from "@Helpers/cognito";
import { parseBankingAndMFAStatus } from "@Helpers/auth";
import { LogoutEvent } from "@Helpers/customEvents/logout";

import * as Types from "./types";

const COMMON_ERROR_CRUMBS = {
  category: "Cognito",
};

// NO-AUTH
function setupAmplify({
  userPoolClientId,
  userType = "profissional",
}: Types.SetupAmplifyProps): void {
  const Cognito = getCognitoContextPool(
    userType as UserTypes,
    userPoolClientId
  );

  Amplify.configure({ Auth: { Cognito } });
}

async function handleSignin({
  username,
  password,
  userType,
}: Types.HandleSigninProps) {
  return performWithErrorHandling({
    perform: async () => {
      const signInOptions = getAmplifySignInOptions(userType);

      const signInOutput = await signIn({
        username: username.toLocaleLowerCase(),
        password: password,
        options: signInOptions,
      });

      if (
        signInOutput.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
      ) {
        CurrentUserStore.getState().setCurrentUser({
          isBanking: true,
          isMFAActive: true,
        });
      }

      return signInOutput;
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleSignin",
    },
  });
}

async function handleFetchAuthSession({
  forceRefresh,
  haveToUpdateCurrentUser,
}: Types.HandleFetchAuthSessionProps) {
  return performWithErrorHandling({
    perform: async () => {
      const authSession = await fetchAuthSession({
        forceRefresh: Boolean(forceRefresh),
      });

      if (Boolean(haveToUpdateCurrentUser)) {
        const { isBanking, isMFAActive } =
          parseBankingAndMFAStatus(authSession);

        CurrentUserStore.getState().setCurrentUser({ isBanking, isMFAActive });
      }

      return authSession;
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleFetchAuthSession",
    },
  });
}

async function isSignedInUser() {
  return performWithErrorHandling({
    perform: async () => {
      const authSessionInfo = await handleFetchAuthSession({
        forceRefresh: false,
        haveToUpdateCurrentUser: false,
      });

      return Boolean(authSessionInfo?.tokens);
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "isSignedInUser",
    },
  });
}

// AUTH
async function handleSignInConfirmation({ vertical, accountId }) {
  return performWithErrorHandling({
    perform: async () => {
      const challengeResponse = `${vertical};${accountId}`;

      const signInConfirmationOutput = await confirmSignIn({
        challengeResponse,
        options: {
          clientMetadata: {
            vertical,
            challenge: "AccountInfo",
          },
        },
      });

      return signInConfirmationOutput;
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleSignInConfirmation",
    },
  });
}

async function getTOTPDetails() {
  return performWithErrorHandling({
    perform: async () => {
      const TOTPDetails = await setUpTOTP();

      return TOTPDetails;
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "getTOTPDetails",
    },
  });
}

async function verifyAndUpdateTOTPSetup({
  codeTOTP,
  setTOTPAsDefaultMFAPrefer,
}: {
  codeTOTP: string;
  setTOTPAsDefaultMFAPrefer: boolean;
}) {
  const _onError = (error) => {
    const customError: Types.CustomErrorProps = {
      type: error.name,
      message: I18n.get(error.message, error.message),
    };

    throw customError;
  };

  return performWithErrorHandling({
    perform: async () => {
      await verifyTOTPSetup({ code: codeTOTP });

      if (!setTOTPAsDefaultMFAPrefer) return;

      await updateMFAPreference({ totp: "PREFERRED" });
    },
    onError: (error) => _onError(error),
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "verifyAndUpdateTOTPSetup",
    },
  });
}

async function afterTokenTOTPApprove({ nextStep, vertical, accountId }) {
  let confirmSignInOutput;
  if (Boolean(accountId)) {
    confirmSignInOutput = await handleSignInConfirmation({
      vertical,
      accountId,
    });
  } else {
    const registeredVerticalIds: Accounts = JSON.parse(
      nextStep.additionalInfo.accountsJson
    );

    const firstKey = Object.keys(registeredVerticalIds)[0];

    let crossVerticalInternalId = registeredVerticalIds[firstKey][0];

    confirmSignInOutput = await handleSignInConfirmation({
      vertical: firstKey,
      accountId: crossVerticalInternalId,
    });
  }

  return confirmSignInOutput;
}

async function approveTOTPToken({ codeTOTP, vertical, accountId }) {
  const _onError = (error) => {
    const customError: Types.CustomErrorProps = {
      type: error.name,
      message: I18n.get(error.message, error.message),
    };

    throw customError;
  };

  return performWithErrorHandling({
    perform: async () => {
      const confirmTOTPOutput = await confirmSignIn({
        challengeResponse: codeTOTP,
      });

      return await afterTokenTOTPApprove({
        vertical,
        accountId,
        nextStep: confirmTOTPOutput.nextStep,
      });
    },
    onError: (error) => _onError(error),
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "approveTOTPToken",
    },
  });
}

async function handleApproveTOTP({ codeTOTP, vertical, accountId }) {
  const { currentUser } = CurrentUserStore.getState();

  if (!currentUser.isMFAActive) {
    return verifyAndUpdateTOTPSetup({
      codeTOTP,
      setTOTPAsDefaultMFAPrefer: currentUser.isBanking,
    });
  }

  return approveTOTPToken({ codeTOTP, vertical, accountId });
}

function clearAuthDataOfStore() {
  // Remove as chaves de autorização em localstorage ao realizar o logout
  localStorage.removeItem("auth");

  CurrentUserStore.getState().clearCurrentUser();
  AuthLocationStore.getState().clearAuthLocations();
}

async function handleSignout(clearUtilityApplication = true) {
  return performWithErrorHandling({
    perform: async () => {
      if (clearUtilityApplication) clearAuthDataOfStore();

      return await signOut();
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleSignout",
    },
  });
}

async function handleMigrationUserSignout() {
  return performWithErrorHandling({
    perform: async () => {
      clearAuthDataOfStore();

      LogoutEvent.dispatchEvent();
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleMigrationUserSignout",
    },
  });
}

async function handleResetPassword(username: string) {
  return performWithErrorHandling({
    perform: async () => {
      await resetPassword({ username: username.toLocaleLowerCase() });
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleResetPassword",
    },
  });
}

async function handleConfirmResetPassword({
  username,
  confirmationCode,
  newPassword,
}: ConfirmResetPasswordInput) {
  return performWithErrorHandling({
    perform: async () => {
      await confirmResetPassword({
        username: username.toLocaleLowerCase(),
        confirmationCode,
        newPassword,
      });
    },
    errorCrumbs: {
      ...COMMON_ERROR_CRUMBS,
      exceptionFrom: "handleConfirmResetPassword",
    },
  });
}

export {
  setupAmplify,
  handleSignin,
  handleSignout,
  isSignedInUser,
  getTOTPDetails,
  handleApproveTOTP,
  handleFetchAuthSession,
  handleSignInConfirmation,
  clearAuthDataOfStore,
  handleMigrationUserSignout,
  handleResetPassword,
  handleConfirmResetPassword,
};
