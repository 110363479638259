import { APPLICATION_JWTS } from "@Constants/application";

export const getUserTypeByJWT = (jwtClient, vertical = "Avec"): UserTypes => {
  const verticalJWTs = APPLICATION_JWTS[vertical];

  if (vertical === "Avec") {
    if (verticalJWTs.QG.includes(jwtClient)) return "qg";
    if (verticalJWTs.PROFESSIONAL.includes(jwtClient)) return "profissional";
    return "cliente";
  }

  if (verticalJWTs.CROSSX_APP.includes(jwtClient)) return "cliente";

  return "profissional";
};
