import { AxiosInstance } from "axios";

import { API } from "./api";
import AvecAPI from "./avecAPI";
import CrossxAPI from "./crossxAPI";
import { createAPIConnection } from "./connection";

const APIFactory = (
  app: AppNames,
  jwtClient: string,
  salonId?: number | string
) => {
  const connection: AxiosInstance = createAPIConnection({
    app,
    jwtClient,
    salonId,
  });

  API.destroyInstance();

  switch (app) {
    case "avec":
      return new API(new AvecAPI(connection));
    case "crossX":
      return new API(new CrossxAPI(connection));
    default:
      throw new Error(
        "Não foi possível criar a conexão com o app: Nome do app não reconhecido"
      );
  }
};

export { APIFactory };
