type Detail<T> = { detail: T };

type cbEvent<T> = (detail: Detail<T>) => void | Promise<void>;

const MFEName = "@hyperlocal/auth";

class CreateCustomEvent<T = {}> {
  private cbEvent: cbEvent<T> | null = null;
  private eventName: string | null = null;

  constructor(eventName: string, prefix: string = MFEName) {
    this.eventName = `${prefix}/${eventName}`;
  }

  public dispatchEvent = (data?: T) => {
    if (!this.eventName) return this;

    const event = new CustomEvent<T>(this.eventName, { detail: data });

    document.dispatchEvent(event);

    return this;
  };

  public setEvent = (cb: cbEvent<T>, once = false) => {
    if (!this.eventName) return this;

    this.cbEvent = cb;

    document.addEventListener<any>(this.eventName, cb, { once });

    return this;
  };

  public removeEvent = () => {
    if (!this.cbEvent || !this.eventName) return this;

    document.removeEventListener<any>(this.eventName, this.cbEvent);

    return this;
  };
}

export default CreateCustomEvent;
