import * as Types from "./types";

import { SentryDebugging } from "./sentry";

export class DebuggingController implements Types.IDebugging {
  private debuggingService!: Types.IDebugging;

  constructor(debuggingService: Types.IDebugging) {
    this.debuggingService = debuggingService;
  }

  public init() {
    this.debuggingService.init();
  }

  public close() {
    this.debuggingService.close();
  }

  public captureException(error: any) {
    this.debuggingService.captureException(error);
  }

  public addBreadcrumb(breadcrumbProps: Types.BreadcrumbProps) {
    this.debuggingService.addBreadcrumb(breadcrumbProps);
  }
}

export const DebuggingTrack = new DebuggingController(new SentryDebugging());
