import { AxiosInstance } from "axios";

import { performWithErrorHandling } from "@Utils/errorHandlerUtils";

import StorageManager from "@Services/localStorageManager";

import * as Types from "./types";
import { ErrorCrumbsProps } from "@Libs/debugging/types";

const COMMON_ERROR_CRUMBS: ErrorCrumbsProps = {
  category: "Vertical API",
};

class BaseAPI {
  constructor(public apiConnection: AxiosInstance) {}

  protected storageManager = StorageManager;

  protected setAppAuthToken(appName: AppNames, authToken: string) {
    let updatedAuthData: Types.AuthData;

    const storedAuth = this.storageManager.getItem("auth");

    if (Boolean(storedAuth)) {
      updatedAuthData = JSON.parse(storedAuth);
      updatedAuthData[appName] = { authToken };
    } else {
      updatedAuthData = { [appName]: { authToken } } as Types.AuthData;
    }

    this.storageManager.setItem("auth", JSON.stringify(updatedAuthData));
  }

  protected async handleWithErrors({
    perform,
    onError,
    errorCrumbs,
  }: Types.HandleWithErrorsProps): Promise<any> {
    return await performWithErrorHandling({
      perform,
      onError,
      errorCrumbs: { ...COMMON_ERROR_CRUMBS, ...errorCrumbs },
    });
  }

  async verifyIsCognitoUser(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async authenticateNonCognitoUser(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async getCognitoUserRegisteredVerticals(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async registerUserToCognito(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async updateUserCognitoVertical(data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async generateAccessTokens(data: any): Promise<any> {
    throw new Error("Method not implemented");
  }
}

export default BaseAPI;
