import { create } from "zustand";

import * as Types from "./types";

const CURRENT_USER_INITIAL_STATE: Types.CurrentUserProps = {
  id: null,
  name: null,
  email: null,
  document: null,
  cognitoId: null,
  companyId: null,
  isBanking: null,
  isMFAActive: null,
  isCognitoUser: null,
  cognitoVerticals: null,
};

const CurrentUserStore = create<Types.CurrentUserStore>((set, get) => ({
  currentUser: CURRENT_USER_INITIAL_STATE,

  clearCurrentUser: () => set({ currentUser: CURRENT_USER_INITIAL_STATE }),

  setCurrentUser: (props: Partial<Types.CurrentUserProps>) => {
    const { currentUser: prevState } = get();

    set({
      currentUser: {
        ...prevState,
        ...props,
      },
    });
  },
}));

export { CurrentUserStore };
