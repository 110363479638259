import { I18n } from "aws-amplify/utils";

import * as AppConstants from "@Constants/application";

import PtBRVocabulary from "@Constants/vocabulary/pt-br.json";

I18n.putVocabulariesForLanguage("pt", PtBRVocabulary);

I18n.setLanguage("pt");

export * from "@Store/currentUser";
export * from "@Store/authLocations";
export * from "@Store/signinAppProps";
export * from "@Store/userCredentials";
export * from "@Store/forgetPassword";

export * as Auth from "@Services/amplify";
export * as Events from "@Services/applicationEvents";

export * from "@Services/api/api";
export * from "@Services/bootstrapApplication";

export * as PropsValidationMFE from "@Helpers/getMFEValidationAccountProps";

export { DebuggingTrack } from "@Libs/debugging";

export const Constants = {
  Application: AppConstants,
};
