import * as avecAdapter from "./avec";
import * as crossXAdapter from "./crossX";

export const RESPONSE_ADAPTERS = {
  avec: {
    onReject: avecAdapter.handleAPIErrors,
    onResolve: avecAdapter.handleAPISuccess,
  },
  crossX: {
    onReject: crossXAdapter.handleAPIErrors,
    onResolve: crossXAdapter.handleAPISuccess,
  },
};
