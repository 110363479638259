import { create } from "zustand";

import * as Types from "./types";

const AUTH_LOCATION_INITIAL_STATE = {
  total: 0,
  selectedAuthLocation: null,
  availableAuthLocations: [],
};

const AuthLocationStore = create<Types.AuthLocationStore>((set) => ({
  ...AUTH_LOCATION_INITIAL_STATE,

  setSelectedAuthLocation: (authLocation: Types.AuthLocationProps) => {
    set({ selectedAuthLocation: authLocation });
  },

  setAvailableAuthLocations: (
    availableAuthLocations: Types.AvailableAuthLocations,
    total
  ) => {
    set({ availableAuthLocations, total });
  },

  clearAuthLocations: () => {
    set(AUTH_LOCATION_INITIAL_STATE);
  },
}));

export { AuthLocationStore };
