import { AMPLIFY_USER_POOL_ID } from "@Constants/amplify";
import * as Types from "./types";

export function getCognitoContextPool(
  userType: UserTypes,
  userPoolClientId: string
) {
  const businessToBusinessCognito = {
    userPoolId: AMPLIFY_USER_POOL_ID.b2b,
    userPoolClientId: userPoolClientId,
  };

  const cognitoOptionsByUserType = {
    qg: businessToBusinessCognito,
    profissional: businessToBusinessCognito,
    cliente: {
      userPoolId: AMPLIFY_USER_POOL_ID.b2c,
      userPoolClientId: userPoolClientId,
    },
  };

  return cognitoOptionsByUserType[userType];
}

export function getAmplifySignInOptions(userType: UserTypes) {
  const businessToBusinessOptions = {
    authFlowType: "CUSTOM_WITH_SRP",
    clientMetadata: {
      challenge: "AccountInfo",
    },
  };

  const signInOptionsByUserType = {
    qg: businessToBusinessOptions,
    profissional: businessToBusinessOptions,
    cliente: {
      authFlowType: "USER_SRP_AUTH",
    },
  };

  return signInOptionsByUserType[userType] as Types.AmplifySigninOptions;
}
