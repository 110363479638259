import { AxiosError, AxiosInstance } from "axios";

import { generateRedirectUrl } from "@Helpers/cognitoRedirectUrl";

import { CurrentUserStore } from "@Store/currentUser/";
import { SigninAppStore } from "@Store/signinAppProps/";
import { AuthLocationStore } from "@Store/authLocations/";
import { UserCredentialsStore } from "@Store/userCredentials/";
import { ForgetPasswordStore } from "@Store/forgetPassword/";

import { IAvecAPIModel as IAPIModel } from "@Types/services/api/verticalApis/avec";

import BaseAPI from "../baseAPI";

class AvecAPI extends BaseAPI implements IAPIModel {
  constructor(apiConnection: AxiosInstance) {
    super(apiConnection);
  }

  verifyIsCognitoUser: IAPIModel["verifyIsCognitoUser"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;
        const { companyId: internalId } =
          CurrentUserStore.getState().currentUser;

        const params = {
          email,
          jwt_client: jwtClient,
          internal_id: internalId,
        };

        const response = await this.apiConnection.get(
          "/cliente/verifica-cadastro-sso",
          {
            params,
          }
        );

        const verificationUserResponse = response.data.data;

        CurrentUserStore.getState().setCurrentUser({
          email: email,
          companyId: internalId,
          id: verificationUserResponse.id,
          cognitoId: verificationUserResponse.sso_id,
          isCognitoUser: verificationUserResponse.criado_no_sso,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "verifyIsCognitoUser",
      },
    });
  };

  findProfessionalByEmail: IAPIModel["findProfessionalByEmail"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const params = { email: data.email, salao_id: data.salonId };

        const response = await this.apiConnection.get(
          "/profissional/sso/buscar",
          {
            params,
          }
        );

        const {
          data: { salao_id },
        } = response.data;

        CurrentUserStore.getState().setCurrentUser({ companyId: salao_id });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "findProfessionalByEmail",
      },
    });
  };

  fetchProfessionalSalons: IAPIModel["fetchProfessionalSalons"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const jwt_client = SigninAppStore.getState().jwtClient;

        const params = { email: data.email, jwt_client };

        const response = await this.apiConnection.get("/salao/lista", {
          params,
        });

        const { saloes, total } = response.data.data;

        const mappedSalons = saloes.map((salon) => ({
          id: salon.id,
          name: salon.nome,
          slug: salon.slug,
          logoName: salon.logo,
          logoUrl: salon.logo_url,
        }));

        const currentSalonId =
          CurrentUserStore.getState().currentUser?.companyId;
        if (Boolean(currentSalonId))
          AuthLocationStore.getState().setSelectedAuthLocation(
            mappedSalons.find((salon) => salon.id === currentSalonId)
          );

        AuthLocationStore.getState().setAvailableAuthLocations(
          mappedSalons,
          total
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "fetchProfessionalSalons",
      },
    });
  };

  authenticateNonCognitoUser: IAPIModel["authenticateNonCognitoUser"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const jwtClient = SigninAppStore.getState().jwtClient;
        const params = { ...data, jwt_cliente: jwtClient };

        const response = await this.apiConnection.post("/sso/signin", params);

        const authToken = response.data.data.access_token;
        const {
          name,
          salao_acesso_id: salonAccessId,
          sso,
        } = response.data.data;

        this.setAppAuthToken("avec", authToken);

        CurrentUserStore.getState().setCurrentUser({
          name,
          salonAccessId: salonAccessId || null,
          anotherRoomSalon: sso?.registro_outro_salao || null,
        });

        return response.data.data;
      },
      errorCrumbs: {
        exceptionFrom: "authenticateNonCognitoUser",
      },
    });
  };

  getCognitoUserRegisteredVerticals: IAPIModel["getCognitoUserRegisteredVerticals"] =
    async () => {
      return await this.handleWithErrors({
        perform: async () => {
          const jwtClient = SigninAppStore.getState().jwtClient;
          const email = CurrentUserStore.getState().currentUser.email;

          const params = {
            email,
            jwt_client: jwtClient,
          };

          const response = await this.apiConnection.get(
            "/cliente/cognito/verifica-cadastro-sso",
            {
              params,
            }
          );

          CurrentUserStore.getState().setCurrentUser({
            cognitoVerticals: response?.data?.data?.Verticals || null,
          });

          return response.data;
        },
        errorCrumbs: {
          exceptionFrom: "getCognitoUserRegisteredVerticals",
        },
      });
    };

  registerUserToCognito: IAPIModel["registerUserToCognito"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { jwtClient, userType } = SigninAppStore.getState();

        const isB2B = userType === "profissional";

        const internalId = isB2B
          ? CurrentUserStore.getState().currentUser.companyId
          : undefined;

        const response = await this.apiConnection.post(
          "/usuario/cadastrar/sso",
          {
            email: data.email,
            jwt_client: jwtClient,
            password: data.password,
            internal_id: internalId,
            redirect_url: generateRedirectUrl(jwtClient, "Avec"),
          }
        );

        const {
          data: { criado_no_sso },
        } = response.data;

        CurrentUserStore.getState().setCurrentUser({
          isCognitoUser: criado_no_sso,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "registerUserToCognito",
      },
    });
  };

  updateUserCognitoVertical: IAPIModel["updateUserCognitoVertical"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email, vertical, internalId } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;

        const response = await this.apiConnection.put(
          "/usuario/vertical/cadastrar/sso",
          {
            email,
            vertical,
            jwt_client: jwtClient,
            internal_id: internalId || undefined,
          }
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "updateUserCognitoVertical",
      },
    });
  };

  generateAccessTokens: IAPIModel["generateAccessTokens"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { cognitoToken } = data;

        const { jwtClient, deviceInfo } = SigninAppStore.getState();
        const salonId = CurrentUserStore.getState().currentUser.companyId;
        const { email, password } =
          UserCredentialsStore.getState().userCredentials;

        const tokenPush = deviceInfo?.tokenPush;

        const response = await this.apiConnection.post("/auth/amplify/signin", {
          email,
          password,
          salon_id: salonId,
          token_push: tokenPush,
          jwt_client: jwtClient,
          cognito_token: cognitoToken,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "generateAccessTokens",
      },
    });
  };

  forgetPassword: IAPIModel["forgetPassword"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;
        const salonId = CurrentUserStore.getState().currentUser?.companyId;
        const authLocation = AuthLocationStore.getState()?.selectedAuthLocation;
        const authLocationId = authLocation?.id || authLocation?.salonId;

        const response = await this.apiConnection.post(
          "/acesso/v2/esqueci-senha",
          {
            email,
            jwtClient,
            salonId: authLocationId || salonId,
          }
        );

        ForgetPasswordStore.getState().setForgetPasswordData({
          flow: response.data.data.flow,
          type: response.data.data.type,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "forgetPassword",
      },
    });
  };

  sendTokenViaEmail: IAPIModel["sendTokenViaEmail"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const response = await this.apiConnection.get(
          `/cliente/esqueci-senha?user=${data}`
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "sendTokenViaEmail",
      },
    });
  };

  sendNewPassword: IAPIModel["sendNewPassword"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { password, code } = data;

        const salonId = CurrentUserStore.getState().currentUser?.companyId;
        const authLocation = AuthLocationStore.getState()?.selectedAuthLocation;
        const authLocationId = authLocation?.id || authLocation?.salonId;

        const response = await this.apiConnection.post(
          "/cliente/esqueci-senha",
          {
            senha: password,
            codigo: code,
            salao_id: authLocationId || salonId,
          }
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "sendNewPassword",
      },
    });
  };
}

export default AvecAPI;
