import { AxiosInstance } from "axios";

import { recoveryStoredAppAuthToken } from "@Helpers/auth";

import * as Types from "@Types/services/api/utils/connection";

export const setApiHeaders = ({
  api,
  headerProps,
}: Types.SetApiHeaderProps): void => {
  headerProps.forEach((prop) => {
    api.defaults.headers.common[prop.key] = prop.value;
  });
};

export const verifyAuthorizationOnRequest = (
  api: AxiosInstance,
  appName: AppNames
) => {
  api.interceptors.request.use(
    (config) => {
      const appAuthToken = recoveryStoredAppAuthToken(appName);
      config.headers.Authorization = appAuthToken;

      return config;
    },
    (error) => Promise.reject(error)
  );
};
