import { AxiosInstance } from "axios";

import { CurrentUserStore } from "@Store/currentUser/";
import { ForgetPasswordStore } from "@Store/forgetPassword";
import { SigninAppStore } from "@Store/signinAppProps/";
import { UserCredentialsStore } from "@Store/userCredentials/";

import { ICrossXAPIModel as IAPIModel } from "@Types/services/api/verticalApis/crossX";

import BaseAPI from "../baseAPI";
import { generateRedirectUrl } from "@Helpers/cognitoRedirectUrl";

class CrossxAPI extends BaseAPI implements IAPIModel {
  constructor(apiConnection: AxiosInstance) {
    super(apiConnection);
  }

  verifyIsCognitoUser: IAPIModel["verifyIsCognitoUser"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;

        const response = await this.apiConnection.get(
          `/api/1.0/verify-sso/${email}/${jwtClient}`
        );

        const verificationUserResponse = response.data.data;

        CurrentUserStore.getState().setCurrentUser({
          email: email,
          id: verificationUserResponse.id,
          cognitoId: verificationUserResponse.cognito_sso_id,
          isCognitoUser: verificationUserResponse.usuario_cadastrado_sso,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "verifyIsCognitoUser",
      },
    });
  };

  authenticateNonCognitoUser: IAPIModel["authenticateNonCognitoUser"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { username, password } = data;
        const apiClientSecret = process.env["CROSSX_API_CLIENT_SECRET"];
        const CLIENT_ID = "2"; // client_id utilizado por padrão na api crossx;

        const response = await this.apiConnection.post("api/oauth/login", {
          password,
          username,
          scope: "",
          client_id: CLIENT_ID,
          grant_type: "password",
          client_secret: apiClientSecret,
        });

        const token = response.data.access_token;
        const tokenType = response.data.token_type;
        const authToken = `${tokenType} ${token}`;

        this.setAppAuthToken("crossX", authToken);

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "authenticateNonCognitoUser",
      },
    });
  };

  getCognitoUserRegisteredVerticals: IAPIModel["getCognitoUserRegisteredVerticals"] =
    async () => {
      return await this.handleWithErrors({
        perform: async () => {
          const jwtClient = SigninAppStore.getState().jwtClient;
          const email = CurrentUserStore.getState().currentUser.email;

          const response = await this.apiConnection.get(
            `/api/1.0/auth/verify-on-cognito/${email}/${jwtClient}`
          );

          CurrentUserStore.getState().setCurrentUser({
            cognitoVerticals: response?.data?.data?.Verticals || null,
          });

          return response.data;
        },
        errorCrumbs: {
          exceptionFrom: "getCognitoUserRegisteredVerticals",
        },
      });
    };

  registerUserToCognito: IAPIModel["registerUserToCognito"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const jwtClient = SigninAppStore.getState().jwtClient;
        const { email, password } = data;

        const response = await this.apiConnection.post(
          "api/1.0/auth/create-sso",
          {
            email,
            password,
            jwt_client: jwtClient,
            redirect_url: generateRedirectUrl(jwtClient, "CrossX"),
          }
        );

        const {
          data: { criado_no_sso },
        } = response;

        if (criado_no_sso) {
          CurrentUserStore.getState().setCurrentUser({
            isCognitoUser: criado_no_sso,
          });
        }

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "registerUserToCognito",
      },
    });
  };

  forgetPassword: IAPIModel["forgetPassword"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;

        const response = await this.apiConnection.post(
          "/api/1.0/auth/forgot-password",
          {
            email,
            jwt_client: jwtClient,
          }
        );

        ForgetPasswordStore.getState().setForgetPasswordData({
          flow: response.data.data.flow,
          type: response.data.data.type,
        });

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "forgetPassword",
      },
    });
  };

  updateUserCognitoVertical: IAPIModel["updateUserCognitoVertical"] = async (
    data
  ) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { email, vertical, internalId } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;

        const response = await this.apiConnection.put(
          "/api/1.0/auth/add-vertical",
          {
            email,
            vertical,
            jwt_client: jwtClient,
            internal_id: internalId || undefined,
          }
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "updateUserCognitoVertical",
      },
    });
  };

  generateAccessTokens: IAPIModel["generateAccessTokens"] = async (data) => {
    return await this.handleWithErrors({
      perform: async () => {
        const { cognitoToken } = data;
        const jwtClient = SigninAppStore.getState().jwtClient;
        const { email } = UserCredentialsStore.getState().userCredentials;

        const response = await this.apiConnection.post(
          "/api/1.0/cognito/generate-token",
          {
            email,
            jwt_client: jwtClient,
            cognito_token: cognitoToken,
          }
        );

        return response.data;
      },
      errorCrumbs: {
        exceptionFrom: "generateAccessTokens",
      },
    });
  };

  authenticateNonCognitoB2CUser: IAPIModel["authenticateNonCognitoB2CUser"] =
    async (data) => {
      return await this.handleWithErrors({
        perform: async () => {
          const { username, password } = data;

          const response = await this.apiConnection.post(
            "api/1.0/app/auth/sign-in",
            {
              password,
              email: username,
              type: 1, // TODO: quando vier o login social no MFE, terá que implementar o type 2 e 3, facebook e apple respectivamente.
            }
          );

          const token = response.data.data.token;
          const tokenType = "Bearer";
          const authToken = `${tokenType} ${token}`;

          this.setAppAuthToken("crossX", authToken);

          return response.data.data;
        },
        errorCrumbs: {
          exceptionFrom: "authenticateNonCognitoB2CUser",
        },
      });
    };
}

export default CrossxAPI;
