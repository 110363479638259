import { AxiosError } from "axios";

export const handleAPIErrors = (error: AxiosError) => {
  const { response } = error;
  const { data } = response as any;

  return Promise.reject({
    rawError: error,
    code: data.code,
    message:
      data?.message || data?.error || error?.message || "Tente novamente",
  });
};

export const handleAPISuccess = (response) => Promise.resolve(response);
