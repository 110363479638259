export const API_URLS = {
  avec: process.env["API_URL_AVEC"],
  crossX: process.env["API_URL_CROSSX"],
};

export const AVEC_JWTS = {
  QG: ["QgS@laoV1P"],
  PROFESSIONAL: ["T3rm1n4lM0b1l3", "T3rmin@lSV1P", "S@laoV1P", "@VeCg0Pw@"],
};

const CROSSX_JWTS = {
  CROSSX_APP: "@PICr055X",
  CROSSX_DASHBOARD: "Pl@t@f0rm@Cr055X",
};

export const APPLICATION_JWTS = {
  Avec: AVEC_JWTS,
  CrossX: CROSSX_JWTS,
};
