import * as Sentry from "@sentry/react";

import * as Types from "./types";

const NODE_ENV = process.env.NODE_ENV || "";
const SENTRY_DSN = process.env.SENTRY_DSN || "";

export class SentryDebugging implements Types.IDebugging {
  private isSentryEnabled(): boolean {
    return NODE_ENV === "production" && Boolean(SENTRY_DSN);
  }

  private executeIfEnabled(callback: () => void) {
    if (this.isSentryEnabled()) return callback();
  }

  public init() {
    this.executeIfEnabled(() => {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: NODE_ENV || "development",
        sampleRate: 0.1,
        beforeSend(event) {
          if (event.environment === "development") {
            return;
          }

          return event;
        },
      });
    });
  }

  public close() {
    return this.executeIfEnabled(() => {
      Sentry.close();
    });
  }

  public captureException(error: any) {
    this.executeIfEnabled(() => {
      Sentry.captureException(new Error(error));
    });
  }

  public addBreadcrumb(breadcrumbProps: Types.BreadcrumbProps) {
    this.executeIfEnabled(() => {
      Sentry.addBreadcrumb(breadcrumbProps);
    });
  }
}
